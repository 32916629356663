.container {
    min-height: 100svh;
    padding: 80px 0;
    background-color: #EDEEFE;

    .header {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .logo {
        display: block;
        max-width: 100%;
    }

    .hidden {
        display: none;
    }

    .blocks {
        display: flex;
        max-width: 600px;
        flex-direction: column;
        padding: 0 10px;
        margin: 40px auto 20px;
        gap: 15px;
    }
}
